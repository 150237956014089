<template>
  <div>
    <global-header></global-header>
    <div style="width: 1190px; margin: auto;background-color: white;padding: 10px 20px;margin-bottom: 10px;" v-html="consultdetails"></div>
    <footer-links style="margin-top: 10px"></footer-links>
    <global-footer></global-footer>
  </div>
</template>

<script>
  import {getUseraiConsultDetails} from '@/apis/index'

  export default {
    name: "aiDetails",
    components: {
      GlobalHeader: () => import('@PAGE/common/GlobalHeader'),
      GlobalFooter: () => import('@PAGE/common/GlobalFooter'),
      FooterLinks: () => import('@PAGE/common/FooterLinks'),
    },
    data() {
      return {
        consultdetails: ""
      }
    }, created() {
      this.getUseraiConsultDetails();
    },
    methods: {
      getUseraiConsultDetails() {
        let id = this.$route.query.id;
        var data = {
          id: id
        }
        getUseraiConsultDetails(data).then(res => {
          if (res.retdata) {
            this.consultdetails = res.retdata.newsdetails;
          }
        })
      }
    },
  }
</script>

<style scoped>

</style>
